<template>
  <div style="">
    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ totalProducts }} results foundd
              </div>
            </div>
            <div class="view-options d-flex">
              <!-- SortDir Button -->
              <!-- <b-dropdown class="mr-2" v-ripple.400="'rgba(113, 102, 240, 0.15)'" :text="sortDir.text" right variant="outline-primary">
                                        <b-dropdown-item v-for="sortOption in sortDirOptions" :key="sortOption.value" @click="sortDir.text=sortOption.value; sortDir.value=sortOption.value">
                                            {{ sortOption.text }}
                                        </b-dropdown-item>
                                    </b-dropdown> -->
              <!-- Sort by -->
              <!-- <b-dropdown v-ripple.400="'rgba(113, 102, 240, 0.15)'" :text="sortBy.text" right variant="outline-primary">
                                        <b-dropdown-item v-for="sortOption in sortByOptions" :key="sortOption.value" @click="sortBy.text=sortOption.value; sortBy.value=sortOption.value">
                                            {{ sortOption.text }}
                                        </b-dropdown-item>
                                    </b-dropdown> -->
              <!-- Item View Radio Button Group  -->
              <!-- <b-form-radio-group v-model="itemView" class="ml-1 list item-view-radio-group" buttons size="sm" button-variant="outline-primary">
                                <b-form-radio v-for="option in itemViewOptions" :key="option.value" :value="option.value">
                                    <feather-icon :icon="option.icon" size="18" />
                                </b-form-radio>
                            </b-form-radio-group> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Overlay -->
    <div class="body-content-overlay" />
    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              v-model="filters.q"
              placeholder="Search Product"
              class="search-product"
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
    </div>
    <!-- Prodcuts -->
    <section :class="itemView">
      <b-card
        v-for="product in products"
        :key="product.id"
        class="ecommerce-card"
        no-body
      >
        <div
          class="item-img text-center mt-2 mx-auto d-block"
          style="
            width: 200px;
            height: 200px;
            display: flex;
            justify-content: center;
          "
          width="200px"
          height="200px"
        >
          <b-link :to="{ path: '/Used/ShowProduct/' + product.id }">
            <b-img
              :alt="`${product.id}`"
              fluid
              class="card-img-top"
              style="width: auto; height: 200px"
              :src="product.cover"
            />
          </b-link>
        </div>
        <!-- Product Details -->
        <b-card-body>
          <div style="margin: auto 10px">
            <h4 class="item-name">
              <b-link
                class="text-body"
                :to="{ path: '/Used/ShowProduct/' + product.id }"
              >
                {{ product.name }}
              </b-link>
            </h4>
            <b-card-text style="font-size: 15px" class="item-description">
              {{ product.translation.short_description }}
            </b-card-text>
            <h6 class="item-name mb-1">
              <span class="text-body"> ID:</span> {{ product.id }}
            </h6>
          </div>
        </b-card-body>
        <!-- Product Actions -->
        <div class="item-options text-center">
          <!-- <b-button  variant="danger" @click="deleteItem(product.id)">
                        <feather-icon icon="DeleteIcon" class="mr-50" />
                        <span>Delete</span>
                    </b-button> -->
        </div>
      </b-card>
    </section>
    <!-- Pagination -->
    <section>
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="filters.page"
            :total-rows="totalProducts"
            :per-page="filters.pagi"
            align="center"
            size="sm"
            class="my-0"
            aria-controls="CategoryTable"
          >
            <template #prev-text
              ><feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </section>
    <!-- Sidebar -->
    <!-- <portal to="content-renderer-sidebar-detached-left">
            <shop-left-filter-sidebar :filters="filters" :filter-options="filterOptions" :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar" />
        </portal> -->
  </div>
</template>

<script>
import { watch } from "@vue/composition-api";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";

import {
  useShopFiltersSortingAndPagination,
  useShopUi,
  useShopRemoteData,
} from "./useECommerceShop";
import { useEcommerceUi } from "./useEcommerce";
export default {

  computed: {
    rows() {
      return totalProducts;
    },
  },
  data() {
    return {
      showDismissibleAlert: false,
      errors_back: [],
      itemData: {},
      totalRows: 0,
      fetchD: null,
    };
  },

  methods: {
    deleteItem(id) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete product.", {
          title: "Are You Sure?",
          size: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            axios
              .delete("products/" + id)
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.fetchShopProducts();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
  setup() {
    const {
      filters,
      filterOptions,
      sortBy,
      sortByOptions,
      sortDir,
      sortDirOptions,
    } = useShopFiltersSortingAndPagination();
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi();
    const { itemView, itemViewOptions, totalProducts } = useShopUi();
    const { products, fetchProducts } = useShopRemoteData();
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();
    // Wrapper Function for `fetchProducts` which can be triggered initially and upon changes of filters
    const fetchShopProducts = () => {
      fetchProducts({
        page: filters.value.page,
        pagi: filters.value.pagi,
        sortBy: sortBy.value.value,
        sortDir: sortDir.value.vlaue,
        q: filters.value.q,
        locale: filters.value.locale,
      }).then((response) => {
        const data = response.data.data.data;
        products.value = [];
        for (let index in data) {
          if (data[index].translation != null) {
            products.value.push(data[index]);
          } else {
            products.value.push({
              id: data[index].id,
              cover: data[index].cover,
              translation: {
                name: "undefinded",
                short_description: "undefinded",
              },
            });
          }
        }
        for (let ind in products.value) {
          products.value[ind].name = products.value[ind].translation.name;
          const cove = products.value[ind].cover;
          products.value[ind].image = cove.name;
        }

        // products.value = [...new Map(products.value.map(item => [item.id, item])).values()]
        totalProducts.value = response.data.data.meta.total;
      });
    };
    fetchShopProducts();
    watch(
      [filters, sortBy, sortDir],
      () => {
        fetchShopProducts();
      },
      {
        deep: true,
      }
    );
    return {
      // useShopFiltersSortingAndPagination
      filters,
      filterOptions,
      sortBy,
      sortByOptions,
      sortDir,
      sortDirOptions,
      // useShopUi
      itemView,
      itemViewOptions,
      totalProducts,
      toggleProductInWishlist,
      handleCartActionClick,
      // useShopRemoteData
      products,
      // mqShallShowLeftSidebar
      mqShallShowLeftSidebar,
      fetchShopProducts,
    };
  },
  created() {},
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
